<template>
  <div class="background">
    <div class="popup">
      <p>Login</p>
      <div>
        <input @keyup="formSubmitted($event)" v-model="state.loginInput.username" type="text" placeholder="username" />
        <input
          @keyup="formSubmitted($event)"
          v-model="state.loginInput.password"
          type="password"
          placeholder="password"
        />
      </div>
      <p class="err">{{ loginErr }}</p>
      <img @click="login" src="../assets/right-arrow.svg" class="action-btn" alt="" />
    </div>
  </div>
</template>

<script>
import global from '../composables/global';

export default {
  setup() {
    global.checkLogin();
    return { state: global.state, login: global.login, loginErr: global.loginErr };
  },
  methods: {
    formSubmitted(evnent) {
      if (event.key == 'Enter') {
        this.login();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.404);

  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -200px;
    background: #dfdfdf;
    width: 400px;
    height: 200px;
    border-radius: 6px;
    box-shadow: 0 0 50px 20px black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    p {
      color: #707070;
      font-size: 1em;
      text-align: left;
      margin: 0px;
    }
    input {
      color: #3a3a3a;
      background: #dfdfdf;
      border-bottom: 3px solid #3a3a3a;
      font-size: 1.6em;
      font-weight: 300;
      padding: 10px 0px;
      margin-bottom: 8px;
    }
    img {
      width: 30px;
      margin-left: 3px;
    }
    .err {
      font-size: 0.7em;
      color: #f66565;
    }
  }
}
</style>
